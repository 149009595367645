globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"ae2f9b125c1484541d835c0e0af23ad7b9b59ae5"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { initSentry } from "./sentry.config"
import {
  browserTracingIntegration,
  browserProfilingIntegration,
} from "@sentry/nextjs"

initSentry({
  integrations: [browserTracingIntegration(), browserProfilingIntegration()],
  tracePropagationTargets: ["paragraph.xyz/api", "api.paragraph.xyz"],
})
